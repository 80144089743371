import '../services/utils/iots.service'

import * as React from 'react'
import * as ReactDOM from 'react-dom'
import * as MenuComponent from './shared/menu.component'
import { StyleSheetManager, ThemeProvider } from 'styled-components'
import { isPropValidForForwarding, personalizedTheme } from '../theme'
import RollbarComponent from './shared/rollbar.component'
import { sleep } from '../services/utils/utils.service'
import * as FlashHostComponent from './shared/flashes/flash-host.component'

export default async function autoloadComponents () {
  // This feature is used in some E2E tests that need to patch the global context before the components are loaded
  if (window.location.href.includes('delay_react_loading')) await sleep(1000)

  const w = (window as any)

  const loaders = [
    { selector: '#js-menu-react-container', data: { menu: w.menu }, component: MenuComponent },
    { selector: '#js-flash-host-react-container', data: {}, component: FlashHostComponent },
    { selector: '#js-employee-working-days-react-container', data: w.dataForEmployeeEditor, component: () => import('./employees/employee/working-days/working-days-form.component') },
    { selector: '#js-employee-intervention-sector-react-container', data: w.dataForEmployeeEditor, component: () => import('./employees/employee/intervention-sector/intervention-sector-form.component') },
    { selector: '#js-employee-profile-document-react-container',  data: w.dataForEmployeeEditor, component: () => import('./employees/employee/profile-document/profile-document-form.component') },
    { selector: '#js-employee-principal-information-react-container', data: w.dataForEmployeeEditor, component: () => import('./employees/employee/principal-information/principal-information-form.component') },
    { selector: '#js-employee-skills-react-container', data: w.dataForEmployeeEditor, component: () => import('./employees/employee/skills/skills-form.component') },
    { selector: '#js-employee-third-party-apps-react-container', data: w.dataForEmployeeEditor, component: () => import('./backoffice/employees/third-party-apps/third-party-apps-form.component') },
    { selector: '#js-employee-urssaf-payment-react-container', data: w.dataForEmployeeEditor, component: () => import('./employees/employee/urssaf-payment/urssaf-payment-form.component') },
    { selector: '#js-employee-nova-export-config-react-container', data: w.dataForEmployeeEditor, component: () => import('./employees/employee/nova-export/nova-export-config-form.component') },
    { selector: '#js-employee-offered-services-react-container', data: w.dataForEmployeeEditor, component: () => import('./employees/employee/employee-services/employee-services-form.component') },
    { selector: '#js-employee-stripe-bank-details-react-container', data: w.dataForEmployeeEditor, component: () => import('./employees/employee/stripe-bank-details/stripe-bank-details-form.component') },
    { selector: '#js-employee-bank-information-bank-details-react-container', data: w.dataForEmployeeEditor, component: () => import('./employees/employee/bank-information-bank-details/bank-information-bank-details-form.component') },
    { selector: '#js-clocking-and-incident-react-container', data: { ...w.dataForClocking, event: w.dataForClocking?.events[0], onSubmit: () => window.location.reload() }, component: () => import('./employees/events/event-clocking-and-incident-form.component') },
    { selector: '#js-market-working-days-react-container', data: w.dataForMarketEditor, component: () => import('./backoffice/supplier/working-days/working-days-form.component') },
    { selector: '#js-market-certifications-react-container', data: w.dataForMarketEditor, component: () => import('./backoffice/supplier/certifications/certifications-form.component') },
    { selector: '#js-market-stripe-bank-details-react-container', data: w.dataForMarketEditor, component: () => import('./backoffice/supplier/stripe-bank-details/stripe-bank-details-form.component') },
    { selector: '#js-market-contact-information-react-container', data: w.dataForMarketEditor, component: () => import('./backoffice/supplier/contact-information/contact-information-form.component') },
    { selector: '#js-market-domain-name-react-container', data: w.dataForMarketEditor, component: () => import('./backoffice/supplier/domain-name/domain-name-form.component') },
    { selector: '#js-offered-service-choose-service-react-container', data: w.dataForOfferedServiceEditor, component: () => import('./backoffice/offered-service/choose-service/choose-service-form.component') },
    { selector: '#js-offered-service-zones-react-container', data: w.dataForOfferedServiceEditor, component: () => import('./backoffice/offered-service/zones/zones-form.component') },
    { selector: '#js-offered-service-working-days-react-container', data: w.dataForOfferedServiceEditor, component: () => import('./backoffice/offered-service/working-days/working-days-form.component') },
    { selector: '#js-offered-service-price-react-container', data: w.dataForOfferedServiceEditor, component: () => import('./backoffice/offered-service/price/price-form.component') },
    { selector: '#js-offered-service-quantity-react-container', data: w.dataForOfferedServiceEditor, component: () => import('./backoffice/offered-service/quantity/quantity-form.component') },
    { selector: '#js-offered-service-duration-react-container', data: w.dataForOfferedServiceEditor, component: () => import('./backoffice/offered-service/duration/duration-form.component') },
    { selector: '#js-website-widgets-index-react-container', data: w.websiteWidgets, component: () => import('./backoffice/website-widgets/website-widgets-index.component') },
    { selector: '#js-website-supplier-map-widget-react-container', data: w.dataForWebsiteSupplierMapWidget, component: () => import('./backoffice/website-widgets/website-supplier-map-widget-form.component') },
    { selector: '#js-website-rating-widget-react-container', data: w.dataForWebsiteRatingWidget, component: () => import('./backoffice/website-widgets/website-rating-widget-form.component') },
    { selector: '#js-website-directory-widget-react-container', data: w.dataForWebsiteDirectoryWidget, component: () => import('./backoffice/website-widgets/website-directory-widget-form.component') },
    { selector: '#js-website-casting-widget-react-container', data: w.websiteCastingWidget, component: () => import('./backoffice/website-widgets/website-casting-widget-form.component') },
    { selector: '#js-website-employee-map-widget-react-container', data: w.dataForWebsiteEmployeeMapWidget, component: () => import('./backoffice/website-widgets/website-employee-map-widget-form.component') },
    { selector: '#js-customers-write-nfc-react-container', data: w.dataForNfc, component: () => import('./backoffice/customers/index-write-nfc/customers-write-nfc-tokens.component') },
    { selector: '#js-casting-popin-install-react-container', data: w.dataForPopinInstall, component: () => import('./backoffice/castings/popin-install.component') },
    { selector: '#js-casting-answer-react-container', data: {}, component: () => import('./backoffice/castings/answer/casting-answer-wrapper.component') },
    { selector: '#js-casting-postulate-react-container', data: w.dataForCastingPostulate, component: () => import('./employees/castings/postulate/casting-postulate.component') },
    { selector: '#js-casting-market-answer-react-container', data: w.dataForCastingAnswer, component: () => import('./market/castings/answer/casting-answer.component') },
    { selector: '#js-casting-expired-form-react-container', data: w.dataForExpiredForm, component: () => import('./backoffice/castings/email-forms/casting-email-expired.component') },
    { selector: '#js-castings-index-react-container', data: w.dataForCastingsIndex, component: () => import('./backoffice/castings/index/castings-index.component') },
    { selector: '#js-event-show-react-container', data: w.dataForEventShow, component: () => import('./backoffice/events/event-show.component') },
    { selector: '#js-event-update-react-container', data: w.dataForEventEdit, component: () => import('./backoffice/events/event-update.component') },
    { selector: '#js-event-cancel-react-container', data: w.dataForEventCancel, component: () => import('./backoffice/events/event-cancel.component') },
    { selector: '#js-event-request-cancel-react-container', data: w.dataForEventRequestCancel, component: () => import('./customers/events/event-request-cancel.component') },
    { selector: '#js-event-request-update-react-container', data: w.dataForEventRequestUpdate, component: () => import('./customers/events/event-request-update.component') },
    { selector: '#js-event-selected-options-react-container', data: { ...w.dataForEventSelectedOptions, onSubmit: () => window.location.reload() }, component: () => import('./backoffice/events/event-manage-selected-options.component') },
    { selector: '#js-invoice-form-react-container', data: w.dataForInvoiceForm, component: () => import('./backoffice/invoices/form/invoice-form.component') },
    { selector: '#js-terms-react-container', data: w.dataForTerms, component: () => import('./backoffice/terms/terms.component') },
    { selector: '#js-customer-fields-react-container', data: w.dataForCustomerFields, component: () => import('./backoffice/customer-fields/customer-fields.component') },
    { selector: '#js-employee-fields-react-container', data: w.dataForEmployeeFields, component: () => import('./backoffice/employee-fields/employee-fields.component') },
    { selector: '#js-employee-summary-validate-documents-react-container', data: w.dataForSummary, component: () => import('./backoffice/employees/summary/employee-documents-validation.component') },
    { selector: '.js-bookmark-button-react-container', data: (e: HTMLElement) => ({ id: parseInt(e.getAttribute('data-bookmarkable-id'), 10), type: e.getAttribute('data-bookmarkable-type'), bookmarks: w.bookmarks }), component: () => import('./customers/bookmarks/bookmark-button.component') },
    { selector: '#js-customer-website-directory-widget-react-container', data: { renderDefault: true }, component: () => import('./plugins/directory/website-directory-widget.component') },
    { selector: '#js-export-product-packages-service-deliveries-react-container', data: {}, component: () => import('./backoffice/service-deliveries/packages/export-packages-button.component') },
    { selector: '#js-export-events-react-container', data: {}, component: () => import('./backoffice/events/events-export-button.component') },
    { selector: '#js-edit-service-delivery-address-button-react-container', data: w.dataForAddressEditor, component: () => import('./backoffice/service-deliveries/addresses/edit-service-delivery-address-button.component') },
    { selector: '#js-employee-index-react-container', data: w.dataForEmployeesIndex, component: () => import('./backoffice/employees/index/employees-index.component') },
    { selector: '#js-employees-stripe-index-react-container', data: w.dataForEmployeesIndex, component: () => import('./backoffice/employees/stripe-index/employees-stripe-index.component') },
    { selector: '#js-promo-code-react-container', data: w.dataForPromotionalCodeEditor, component: () => import('./backoffice/promotional-codes/promotional-codes.component') },
    { selector: '#js-offered-service-subscription-choose-service-react-container', data: w.dataForOfferedServiceSubscriptionEditor, component: () => import('./backoffice/offered-service-subscription/choose-service/choose-service-form.component') },
    { selector: '#js-offered-service-subscription-zones-react-container', data: w.dataForOfferedServiceSubscriptionEditor, component: () => import('./backoffice/offered-service-subscription/zones/zones-form.component') },
    { selector: '#js-offered-service-subscription-price-react-container', data: w.dataForOfferedServiceSubscriptionEditor, component: () => import('./backoffice/offered-service-subscription/price/price-form.component') },
    { selector: '#js-offered-service-subscription-quantity-react-container', data: w.dataForOfferedServiceSubscriptionEditor, component: () => import('./backoffice/offered-service-subscription/quantity/quantity-form.component') },
    { selector: '#js-offered-service-subscription-working-days-react-container', data: w.dataForOfferedServiceSubscriptionEditor, component: () => import('./backoffice/offered-service-subscription/working-days/working-days-form.component') },
    { selector: '#js-employee-temporary-sector-react-container', data: w.dataForTemporarySector, component: () => delayDuringTest().then(() => import('./employees/castings/temporary-sector-autodetect.component')) },
    { selector: '#js-casting-plugin-finalize-react-container', data: w.dataForCastingFinalize, component: () => import('./plugins/casting/finalize/casting-finalize.component') },
    { selector: '#js-qrcode-link-react-container', data: w.dataForQRCodeLink, component: () => import('./backoffice/customers/show/customer-qrcode-link.component') },
    { selector: '#js-global-planning-react-container', data: w.dataForPlanning, component: () => import('./backoffice/plannings/global-planning.component') },
    { selector: '#js-single-user-planning-react-container', data: { ...w.dataForPlanning, PlanningComponent: React.lazy(() => import('./backoffice/plannings/single-user-planning.component')) }, component: () => import('./backoffice/plannings/planning-root.component') },
    { selector: '#js-single-user-planning-list-react-container', data: { ...w.dataForPlanning, PlanningComponent: React.lazy(() => import('./backoffice/plannings/single-user-planning-list.component')) }, component: () => import('./backoffice/plannings/planning-root.component') },
    { selector: '#js-single-user-planning-solo-react-container', data: { ...w.dataForPlanning, PlanningComponent: React.lazy(() => import('./backoffice/plannings/planning-solo.component')) }, component: () => import('./backoffice/plannings/planning-root.component') },
    { selector: '#js-show-invoice-react-container', data: w.dataForShowInvoice, component: () => import('./backoffice/invoices/show/show-invoice.component') },
    { selector: '#js-prescription-invitations-index-react-container', data: w.dataForInvitationsIndex, component: () => import('./prescription/invitations/invitations-index.component') },
    { selector: '#js-employee-signup-react-container', data: w.dataForEmployeeSignUp, component: () => import('./employees/signup/signup-employee.component') },
    { selector: '#js-messaging-conversations-react-container', data: w.dataForMessagingConversationIndex, component: () => import('./backoffice/messaging-conversations/index/messaging-conversations-index.component') },
    { selector: '#js-show-messaging-conversation-react-container', data: w.dataForShowMessagingConversation, component: () => import('./backoffice/messaging-conversations/show/messaging-conversation-show.component') },
    { selector: '#js-upload-messaging-conversation-backoffice-react-container', data: (e: HTMLElement) => ({ recipientIds: e.getAttribute('data-recipients')?.split(',') || [] }), component: () => import('./backoffice/messaging-conversations/new-messaging-conversation.component') },
    { selector: '#js-upload-messaging-conversation-customer-or-employee-react-container', data: w.dataForNewConversation, component: () => import('./customers/messaging-conversations/new-messaging-conversation.component') },
    { selector: '#js-ratings-index-react-container', data: {}, component: () => import('./backoffice/ratings/ratings-index.component') },
    { selector: '#js-invoices-index-react-container', data: {}, component: () => import('./backoffice/invoices/index/invoices-index.component') },
    { selector: '#js-mobile-application-template-form', data: w.dataForMobileApplicationTemplateEditor, component: () => import('./backoffice/mobile-application-templates/mobile-application-template-form.component') },
    { selector: '#js-outbound-notifications-index-react-container', data: w.dataForOutboundNotificationsIndex, component: () => import('./backoffice/outbound-notifications/outbound-notifications-index.component') },
    { selector: '#js-notifier-action-settings-index-react-container', data: w.dataForNotificatierActionSettingsIndex, component: () => import('./backoffice/notifier-action-settings/notifier-action-settings-index.component') },
    { selector: '#js-supplier-signup-form', data: w.dataForSupplierSignup, component: () => import('./suppliers/signup/signup-supplier.component') },
    { selector: '#js-prescriber-signup-form', data: w.dataForPrescriberSignup, component: () => import('./prescribers/signup/signup-prescriber.component') },
    { selector: '#js-backoffice-marketplace-offered-services-react-container', data: w.dataForMarketplaceOfferedServices, component: () => import('./backoffice/marketplaces/marketplace-offered-services.component') },
    { selector: '#js-prescription-marketplace-offered-services-react-container', data: w.dataForMarketplaceOfferedServices, component: () => import('./prescription/marketplaces/marketplace-offered-services.component') },
    { selector: '#js-customers-index-react-container', data: w.dataForCustomersIndex, component: () => import('./backoffice/customers/index/customers-index.component') },
    { selector: '#js-subscribed-plan-option-index-react-container', data: w.dataForReactSubscribedPlanOptions, component: () => import('./backoffice/subscribed_plan_options/subscribed-plan-option-index.component') },
    { selector: '#js-credit-add-widget-react-container', data: w.dataForCreditAddWidget, component: () => import('./backoffice/credits/credit-add-widget.component') },
    { selector: '#js-credit-menu-bar-container', data: w.dataForCreditMenuBar, component: () => import('./backoffice/credits/credit-menu-bar.component') },
    { selector: '#js-service-delivery-payment-react-container', data: w.dataForServiceDeliveryForm, component: () => import('./customers/proposals/service-deliveries/service-delivery-payment-form.component') },
    { selector: '#js-service-add-participant-react-container', data: w.dataForServiceDeliveryForm, component: () => import('./customers/proposals/service-deliveries/service-delivery-add-participant-form.component') },
    { selector: '#js-backoffice-casting-show-right-block-react-container', data: w.dataForCastingShowRightBlock, component: () => import('./backoffice/castings/show/casting-show-right-block.component') },
    { selector: '#js-modal-changelog-react-container', data: {}, component: () => import('./backoffice/castings/modal-changelog.component') },
    { selector: '#js-customer-urssaf-registration-payload-show-react-container', data: w.dataForUrssafRegistrationPayloadShow, component: () => import('./customers/urssaf-registration-payloads/urssaf-registration-payload-show.component') },
    { selector: '#js-employee-ratings-index-react-container', data: {}, component: () => import('./employees/ratings/employee-ratings-index.component') },
    { selector: '#js-accounting-sync-oobe-configuration-react-container', data: w.dataForAccountingSyncOobe, component: () => import('./backoffice/accounting-syncs/accounting-sync-oobe-index.component') },
    { selector: '#js-document-create-react-container', data: w.dataForNewDocument, component: () => import('./backoffice/documents/create-document-form.component') },
    { selector: '#js-module-employee-office-broadcast-casting-ordering-criteria-picker', data: w.dataForOrderingCriteriaOrder, component: () => import('./backoffice/supplier/enabled-modules/module-employee-office-broadcast-casting-ordering.component') },
    { selector: '#js-oauth-application-react-container', data: w.dataForOauthApplicationsIndex, component: () => import('./backoffice/oauth-applications/oauth-applications-index.component') },
    { selector: '#js-oauth-application-form-react-container', data: w.dataForOauthApplicationForm, component: () => import('./backoffice/oauth-applications/oauth-application-form.component') },
    { selector: '#js-customer-confirm-delete-account-container', data: w.dataForCustomerDeletion, component: () => import('./customers/delete/customer-request-delete.component') },
    { selector: '#js-backoffice-delete-customer-button-container', data: w.dataForCustomerDeleteButton, component: () => import('./backoffice/customers/show/customer-delete-button.component') },
    { selector: '#js-pay-document-react-container', data: w.dataForPayDocument, component: () => import('./customers/documents/document-pay-form.component') },
    { selector: '#js-casting-postulate-without-employees-react-container', data: w.dataForCastingPostulateWithoutEmployees, component: () => import('./backoffice/castings/postulate-without-employees/casting-postulate-without-employees.component') },
    { selector: '#js-internal-notes-react-container', data: w.dataForInternalNotes, component: () => import('./backoffice/internal-notes/internal-notes-index.component') },
    { selector: '#js-backoffice-casting-show-react-container', data: w.dataForCastingShow, component: () => import('./backoffice/castings/show/casting-show.component') },
    { selector: '#js-market-casting-show-react-container', data: w.dataForCastingShow, component: () => import('./market/castings/show/casting-show.component') },
    { selector: '#js-event-invoice-event-react-container', data: w.dataForEventInvoicing, component: () => import('./employees/events/event-invoicing-modal.component') },
    { selector: '#js-customer-employee-ratings-show-react-container', data: w.dataForCustomerEmployeeRatings, component: () => import('./customers/employee/customer-employee-ratings-show.component') },
    { selector: '#js-customer-employee-rating-new-react-container', data: w.dataForCustomerEmployeeNewRating, component: () => import('./customers/employee/customer-employee-rating-new.component') },
    { selector: '#js-employee-formalities-stats-react-container', data: w.dataForEmployeeFormalitiesStats, component: () => import('./employees/formalities-stats/formalities-stats.component') },
    { selector: '#js-payment-transactions-index-react-container', data: w.dataForPaymentTransactionIndex || {}, component: () => import('./backoffice/payment-transactions/payment-transactions-index.component') },
    { selector: '#js-customer-proposal-service-delivery-quotation-react-container', data: w.dataForServiceDeliveryQuotation, component: () => import('./customers/proposals/service-deliveries/service-delivery-quotation.component') },
    { selector: '#js-backoffice-coupons-packs-index-react-container', data: {}, component: () => import('./backoffice/coupons-packs/coupons-packs-index.component') },
    { selector: '#js-customer-bought-coupons-packs-index-react-container', data: w.dataForBoughtCouponsPackIndex, component: () => import('./customers/bought-coupons-packs/bought-coupons-packs-index.component') },
    { selector: '#js-customer-proposal-service-delivery-add-address-react-container', data: w.dataForServiceDeliveryAddAddress, component: () => import('./customers/proposals/service-deliveries/service-delivery-add-address.component') },
    { selector: '#js-market-plans-index-react-container', data: {}, component: () => import('./market/plans/index/plans-index.component') },
    { selector: '#js-market-plans-edit-react-container', data: w.dataForMarketPlansEdit, component: () => import('./market/plans/edit/plan-edit.component') },
    { selector: '#js-quotations-index-react-container', data: {}, component: () => import('./backoffice/quotations/index/quotations-index.component') },
    { selector: '#js-quotation-show-react-container', data: w.dataForQuotationShow, component: () => import('./backoffice/quotations/show/quotation-show.component') },
    { selector: '#js-quotation-prepare-invoice-react-container', data: w.dataForQuotationPrepareInvoice, component: () => import('./backoffice/quotations/prepare-invoice/quotation-prepare-invoice.component') },
    { selector: '#js-graphene-documentation-react-container', data: {}, component: () => import('./swagger/graphene-documentation.component') },
    { selector: '#js-customer-proposal-service-delivery-summary-react-container', data: w.dataForCustomerProposalServiceDeliverySummary, component: () => import('./customers/proposals/service-deliveries/service-delivery-summary.component') },
    { selector: '#js-customer-proposal-service-delivery-confirm-react-container', data: w.dataForCustomerProposalServiceDeliveryConfirm, component: () => import('./customers/proposals/service-deliveries/service-delivery-confirm.component') },
    { selector: '#js-customer-proposal-service-delivery-start-date-react-container', data: w.dataForCustomerProposalServiceDeliveryStartDate, component: () => import('./customers/proposals/service-deliveries/service-delivery-start-date.component') },
    { selector: '#js-customer-proposal-service-delivery-cart-react-container', data: w.dataForCustomerProposalServiceDeliveryCart, component: () => import('./customers/proposals/service-deliveries/service-delivery-cart.component') },
    { selector: '#js-jitsi-react-container', data: w.joinMeetingParams, component: () => import('./shared/jitsi-container.component') },
    { selector: '#js-customer-proposal-service-delivery-appointments-react-container', data: w.dataForCustomerProposalServiceDeliveryAppointments, component: () => import('./customers/proposals/service-deliveries/service-delivery-appointments.component') },
    { selector: '#js-customer-proposal-service-delivery-appointments-place-react-container', data: w.dataForServiceDeliveryAppointmentsPlace, component: () => import('./customers/proposals/service-deliveries/service-delivery-appointments-place.component') },
    { selector: '#js-customer-proposal-service-delivery-casting-slots-react-container', data: w.dataForServiceDeliveryCastingSlots, component: () => import('./customers/proposals/service-deliveries/service-delivery-casting-slots.component') },
    { selector: '#js-casting-postulate-appointments-react-container', data: w.dataForCastingPostulateAppointments, component: () => import('./employees/castings/postulate/casting-postulate-appointments.component') },
    { selector: '#js-employee-appointments-index-react-container', data: w.dataForAppointmentsIndex, component: () => import('./employees/appointments/appointments-index.component') },
    { selector: '#js-mandate-display-template-react-container', data: w.dataForMandateDisplayTemplate, component: () => import('./shared/mandates/mandate-display-template.component') },
    { selector: '#js-backoffice-bank-information-show-react-container', data: w.dataForBankInformation, component: () => import('./backoffice/bank-informations/bank-information-show.component') },
    { selector: '#js-twilio-pay-call-attempts-index-react-container', data: {}, component: () => import('./backoffice/twilio-pay-call-attempts/twilio-pay-call-attempts-index.component') },
    { selector: '#js-twilio-pay-call-attempt-show-react-container', data: w.dataForTwilioPayCallAttemptShow, component: () => import('./backoffice/twilio-pay-call-attempts/twilio-pay-call-attempt-show.component') },
    { selector: '#js-customer-event-broadcast-confirmation-requests-react-container', data: w.dataForEventBroadcastConfirmationRequests, component: () => import('./customers/event-broadcast-confirmation-requests/event-broadcast-confirmation-requests.component') },
    { selector: '#js-stripe-terminals-index-index-react-container', data: {}, component: () => import('./backoffice/stripe-terminals/stripe-terminals-index.component') },
    { selector: '#js-card-create-on-behalf-of-customer-react-container', data: w.dataForNewCard, component: () => import('./backoffice/customers/cards/card-create-on-behalf-of-customer.component') },
    { selector: '#js-market-plan-option-subscriptions-index-react-container', data: w.dataForMarketPlanOptionSubscriptionsIndex, component: () => import('./market/plan-option-subscriptions/plan-option-subscriptions-index.component') },
    { selector: '#js-customer-casting-show-react-container', data: w.dataForCustomerCastingShow, component: () => import('./customers/castings/show/customer-casting-show.component') },
    { selector: '#js-employee-casting-show-react-container', data: w.dataForEmployeeCastingShow, component: () => import('./employees/castings/show/casting-show.component') },
    { selector: '#js-employee-appointment-proposals-index-react-container', data: w.dataForAppointmentProposalsIndex, component: () => import('./employees/appointment-proposals/appointment-proposals-index.component') },
    { selector: '#js-customer-follow-up-liaison-book-messaging-conversation-preview-react-container', data: w.dataForCustomerFollowUpLiaisonBookMessagingConversationPreview, component: () => import('./shared/customer-follow-up-liaison-book/customer-follow-up-liaison-book-messaging-conversation-preview.component') },
    { selector: '#js-user-edit-payment-facilities-form-react-container', data: w.dataForUserEditPaymentFacilities, component: () => import('./shared/users/payment-modes/user-payment-mode-edit.component') },
    { selector: '#js-backoffice-edit-payment-facilities-form-react-container', data: w.dataForBackofficeEditPaymentFacilities, component: () => import('./backoffice/supplier/payment-modes/payment-modes-form.component') },
    { selector: '#js-shared-nova-export-payloads-index-react-container', data: {}, component: () => import('./shared/nova-export-payloads/nova-export-payloads-index.component') },
    { selector: '#js-employee-debug-mobile-app-react-container', data: w.dataForPushDebugging, component: () => import('./employees/debugging/debug-mobile-app.component') },
    { selector: '#js-backoffice-loyalty-campaigns-index-react-container', data: w.dataForLoyaltyCampaignIndex, component: () => import('./backoffice/loyalty-campaigns/loyalty-campaigns-index.component') },
    { selector: '#js-backoffice-loyalty-campaign-edit-react-container', data: w.dataForLoyaltyCampaignEditor, component: () => import('./backoffice/loyalty-campaigns/loyalty-campaign-edit.component') },
    { selector: '#js-backoffice-loyalty-campaign-show-react-container', data: w.dataForLoyaltyCampaignShow, component: () => import('./backoffice/loyalty-campaigns/loyalty-campaign-show.component') },
    { selector: '#js-backoffice-quotation-before-casting-form-react-container', data: w.dataForQuotationForm, component: () => import('./backoffice/quotations/before-casting/quotation-before-casting-form.component') },
    { selector: '#js-backoffice-coupons-packs-edit-react-container', data: w.dataForCouponsPackEdit, component: () => import('./backoffice/coupons-packs/coupons-pack-edit.component') },
    { selector: '#js-backoffice-terms-form-react-container', data: w.dataForTermsForm, component: () => import('./backoffice/supplier/terms/terms-form.component') },
    { selector: '#js-gdpr-campaigns-consent-form-react-container', data: w.dataForGdprCampaignsConsentForm, component: () => import('./shared/gdpr-campaigns/gdpr-campaigns-consent-form.component') },
    { selector: '#js-gdpr-viewers-index-react-container', data: w.dataForGdprViewersIndex, component: () => import('./shared/gdpr-viewers/gdpr-viewers-index.component') },
    { selector: '#js-backoffice-customer-documents-index-react-container', data: w.dataForDocumentsIndex, component: () => import('./backoffice/customers/documents/backoffice-customer-documents-index.component') },
    { selector: '#js-backoffice-employee-documents-index-react-container', data: w.dataForDocumentsIndex, component: () => import('./backoffice/employees/documents/backoffice-employee-documents-index.component') },
    { selector: '#js-customer-documents-index-react-container', data: w.dataForDocumentsIndex, component: () => import('./customers/documents/customer-documents-index.component') },
    { selector: '#js-employee-documents-index-react-container', data: w.dataForDocumentsIndex, component: () => import('./employees/documents/employee-documents-index.component') },
    { selector: '#js-prescriber-documents-index-react-container', data: w.dataForDocumentsIndex, component: () => import('./prescription/documents/prescription-documents-index.component') },
    { selector: '#js-backoffice-documents-index-react-container', data: w.dataForDocumentsIndex, component: () => import('./backoffice/documents/backoffice-documents-index.component') },
    { selector: '#js-market-documents-index-react-container', data: w.dataForDocumentsIndex, component: () => import('./market/documents/market-documents-index.component') },
    { selector: '#js-prescription-customer-documents-index-react-container', data: w.dataForDocumentsIndex, component: () => import('./prescription/customers/documents/prescription-customer-documents-index.component') },
    { selector: '#js-backoffice-marketplace-show-react-container', data: w.dataForMarketplaceShow, component: () => import('./backoffice/marketplaces/marketplace-show.component') },
    { selector: '#js-market-supplier-show-react-container', data: w.dataForSupplierShow, component: () => import('./market/suppliers/show/supplier-show.component') },
    { selector: '#js-market-independant-supplier-show-react-container', data: w.dataForSupplierShow, component: () => import('./market/suppliers/show-independant/supplier-show-independant.component') },
    { selector: '#js-backoffice-memberships-index-react-container', data: w.dataForMembershipsIndex, component: () => import('./backoffice/memberships/index/backoffice-memberships-index.component') },
    { selector: '#js-backoffice-marketplaces-index-react-container', data: w.dataForMarketplacesIndex, component: () => import('./backoffice/marketplaces/index/backoffice-marketplaces-index.component') },
    { selector: '#js-market-memberships-index-react-container', data: w.dataForMembershipsIndex, component: () => import('./market/memberships/index/market-memberships-index.component') },
    { selector: '#js-market-independant-memberships-index-react-container', data: w.dataForMembershipsIndex, component: () => import('./market/memberships/index-independant/market-independant-memberships-index.component') },
    { selector: '#js-market-suppliers-index-react-container', data: w.dataForSuppliersIndex, component: () => import('./market/suppliers/index/market-suppliers-index.component') },
    { selector: '#js-market-prescribers-invite-button-react-container', data: w.dataForPrescribersInviteButton, component: () => import('./market/prescribers/prescriber-invite-button.component') },
    { selector: '#js-cdc-dac7-exports-index-react-container', data: w.dataForCdcDac7ExportsIndex, component: () => import('./backoffice/cdc-dac7-exports/cdc-dac7-exports-index.component') },
    { selector: '#js-market-profile-document-react-container', data: w.dataForMarketEditor, component: () => import('./backoffice/supplier/profile-document/profile-documents-form.component') },
    { selector: '#js-grouped-bank-transfers-index-react-container', data: {}, component: () => import('./backoffice/grouped-bank-transfers/grouped-bank-transfers-index.component') },
    { selector: '#js-grouped-bank-transfer-show-react-container', data: w.dataForGroupedBankTransferShow, component: () => import('./backoffice/grouped-bank-transfers/grouped-bank-transfer-show.component') },
    { selector: '#js-new-dashboard-form-react-container', data: {}, component: () => import('./backoffice/dashboards/dashboard-new.component') },
    { selector: '#js-default-dashboard-form-react-container', data: w.dataForDashboardDefault, component: () => import('./backoffice/dashboards/dashboard-default.component') },
    { selector: '#js-edit-dashboard-form-react-container', data: w.dataForDashboardEdit, component: () => import('./backoffice/dashboards/dashboard-edit.component') },
    { selector: '#js-employee-plan-subscriptions-index-react-container', data: w.dataForEmployeePlanSubscriptions, component: () => import('./employees/employee-plan-subscriptions/employee-plan-subscription-index.component') },
    { selector: '#js-backoffice-employee-plan-subscriptions-index-react-container', data: w.dataForEmployeePlanSubscriptions, component: () => import('./backoffice/employee-plan-subscriptions/index/employee-plan-subscriptions-index.component') },
    { selector: '#js-employee-accept-next-subscription-price-modal-react-container', data: w.dataForAcceptNextSubscriptionPriceModal, component: () => import('./employees/dashboard/accept-next-subscription-price-modal.component') },
    { selector: '#js-legacy-flash-react-container', data: w.dataForFlash, component: () => import('./shared/flashes/flash.component') },
    { selector: '#js-flash-message-account-deactivated-react-container', data: w.dataForFlashMessageAccountDeactivated, component: () => import('./shared/flashes/flash.component') },
    { selector: '#js-flash-message-account-will-be-deactivated-react-container', data: w.dataForFlashMessageAccountWillBeDeactivated, component: () => import('./shared/flashes/flash-account-will-be-deactivated.component') },
    { selector: '#js-flash-message-incomplete-store-react-container', data: w.dataForFlashMessageIncompleteStore, component: () => import('./shared/flashes/flash-incomplete-store.component') },
    { selector: '#js-calendar-popin-install-react-container', data: w.dataForPopinInstall, component: () => import('./shared/calendar/popin-install.component') },
    { selector: '#js-market-prescriber-reward-react-container', data: w.dataForPrescriberReward, component: () => import('./market/prescribers/prescriber-reward.component') },
    { selector: '#js-prescription-prescriber-discount-percentage-input-react-container', data: w.dataForPrescriberDiscountPercentage, component: () => import('./prescription/modules/prescriber/prescriber-discount-percentage-input.component') },
    { selector: '#js-module-activity-react-container', data: w.dataForModuleActivity, component: () => import('./backoffice/supplier/enabled-modules/modules/module-activity.component') },
    { selector: '#js-module-attestation-fiscale-react-container', data: w.dataForModuleAttestationFiscale, component: () => import('./backoffice/supplier/enabled-modules/modules/module-attestation-fiscale.component') },
    { selector: '#js-module-complex-planning-react-container', data: w.dataForModuleComplexPlanning, component: () => import('./backoffice/supplier/enabled-modules/modules/module-complex-planning.component') },
    { selector: '#js-module-customer-follow-up-liaison-book-react-container', data: w.dataForModuleCustomerFollowUpLiaisonBook, component: () => import('./backoffice/supplier/enabled-modules/modules/module-customer-follow-up-liaison-book.component') },
    { selector: '#js-module-incident-reporting-react-container', data: w.dataForModuleIncidentReporting, component: () => import('./backoffice/supplier/enabled-modules/modules/module-incident-reporting.component') },
    { selector: '#js-module-task-react-container', data: w.dataForModuleTask, component: () => import('./backoffice/supplier/enabled-modules/modules/module-task.component') },
    { selector: '#js-module-prescription-react-container', data: w.dataForModulePrescription, component: () => import('./backoffice/supplier/enabled-modules/modules/module-prescription.component') },
    { selector: '#js-module-payment-react-container', data: w.dataForModulePayment, component: () => import('./backoffice/supplier/enabled-modules/modules/module-payment.component') },
    { selector: '#js-module-accounting-react-container', data: w.dataForModuleAccounting, component: () => import('./backoffice/supplier/enabled-modules/modules/module-accounting.component') },
    { selector: '#js-module-supplier-react-container', data: w.dataForModuleSupplier, component: () => import('./backoffice/supplier/enabled-modules/modules/module-supplier.component') },
    { selector: '#js-module-invoicing-react-container', data: w.dataForModuleInvoicing, component: () => import('./backoffice/supplier/enabled-modules/modules/module-invoicing.component') },
    { selector: '#js-module-quantity-discount-react-container', data: w.dataForModuleQuantityDiscount, component: () => import('./backoffice/supplier/enabled-modules/modules/module-quantity-discount.component') },
    { selector: '#js-backoffice-employee-navigation-react-container', data: w.dataForEmployeeNavigation, component: () => import('./backoffice/employees/backoffice-employee-navigation.component') },
    { selector: '#js-clockings-index-react-container', data: w.dataForClockingsIndex, component: () => import('./backoffice/clockings/clockings-index.component') },
    { selector: '#js-customer-selected-quantity-discounts-index-react-container', data: w.dataForSelectedQuantityDiscountsIndex, component: () => import('./customers/selected-quantity-discounts/selected-quantity-discounts-index.component') },
    { selector: '#js-customer-header-react-container', data: w.dataForCustomerHeader, component: () => import('./backoffice/customers/show/customer-header.component') }
  ]

  for (const loader of loaders) {
    const elements = document.querySelectorAll(loader.selector)

    for (const element of Array.from(elements)) {
      if (element.getAttribute('react-loaded') === '1') continue

      const EntryComponent: any = loader.component instanceof Function ? await loader.component() : loader.component
      if (!EntryComponent || !EntryComponent.default) throw new Error(`Was not able to load component for selector ${loader.selector}`)

      const jsxElement = <RollbarComponent>
        <ThemeProvider theme={ personalizedTheme(window.marketColors) }>
          <StyleSheetManager shouldForwardProp={ isPropValidForForwarding }>
            <EntryComponent.default { ...(loader.data instanceof Function ? loader.data(element) : loader.data) } />
          </StyleSheetManager>
        </ThemeProvider>
      </RollbarComponent>

      ReactDOM.render(jsxElement, element)
  
      // do not mount the same react container multiple times
      element.setAttribute('react-loaded', '1')
    }
  }
}

/** Some rspec/capybara specs need to mutate the global object before components mounts.  */
function delayDuringTest () {
  if (window.env === 'test') return new Promise(resolve => setTimeout(resolve, 500))
  return Promise.resolve()
}
